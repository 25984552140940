
import { Component, Vue } from "vue-property-decorator";
import LayoutDefault from "../layouts/LayoutDefault.vue";
import MainBanner from "../components/MainBanner.vue";
import TitleText from "../components/TitleText.vue";
import Tabs from "../components/Tabs.vue";
import { TabData as iTab } from "@/interfaces/TabData.interface";

@Component<any>({
  metaInfo() {
    return {
      title: "Leighton Resources - Tech, Talent, Together",
      meta: [
        {
          vmid: "description",
          name: "description",
          content: `${this.banner.text}`
        }
      ]
    };
  },
  components: {
    LayoutDefault,
    MainBanner,
    TitleText,
    Tabs
  }
})
export default class Resources extends Vue {
  private banner = {
    heading: " Blogs, case studies, research material & ",
    emphasis: "upcoming events",
    text:
      "Take a look at our news updates, blogs, case studies, research material and upcoming events",
    action: {
      text: "Take a look"
    },
    image: require("../assets/banner-images/resources.png")
  };

  private titleText1 = {
    customClass: "mt-md",
    title: `
      A collection of our resources
    `,
    subTitle: `
        Browse our news updates, events, case studies, blogs and resources – any questions, please get in touch.
    `,
    decorator: [
      {
        image: require("../assets/page-decorators/bubble5.png"),
        type: "bubble-one d-none d-lg-block",
        top: "700px",
        right: "-250px"
      },
      {
        image: require("../assets/page-decorators/green-pill.png"),
        type: "green-pill d-none d-lg-block",
        top: "1000px",
        right: "-1250px"
      },
      {
        image: require("../assets/page-decorators/purple-pill-inverted.png"),
        type: "purple-pill d-none d-lg-block",
        top: "500px",
        right: "1200px",
        height: "700px"
      },
      {
        image: require("../assets/page-decorators/green-pill.png"),
        type: "green-pill d-md-none",
        top: "950px",
        right: "-550px",
        height: "300px"
      },
      {
        image: require("../assets/page-decorators/purple-pill-inverted.png"),
        type: "purple-pill d-md-none",
        top: "650px",
        right: "250px",
        height: "280px"
      }
    ]
  };

  private tabs: Array<iTab> = [
    {
      id: 0,
      active: true,
      title: "Blog Posts",
      key: "blog",
      customClass: "blog-post--cards"
    },
    {
      id: 1,
      active: false,
      title: "Events",
      key: "event"
    },
    {
      id: 2,
      active: false,
      title: "Case Studies",
      key: "casestudy"
    },
    {
      id: 3,
      active: false,
      title: "Resources",
      key: "resource"
    },
    {
      id: 4,
      active: false,
      title: "News",
      key: "news"
    }
  ];

  private tabChanged(type: number) {
    this.tabs.map(item => {
      if (item.active === true) {
        // set previous item = to false
        item.active = false;
      }
      if (item.id === type) {
        // set the new item to true if the id matches the type
        item.active = true;
      }
      return;
    });
  }
}
