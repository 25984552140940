
import { Component, Prop, Vue } from "vue-property-decorator";
import IconLoading from "@/components/common-ui/IconLoading.vue";
import BlogPostCard from "@/components/cards-ui/BlogPostCard.vue";
import { TabData as iTab } from "@/interfaces/TabData.interface";
import { BlogCardData as IBlogCardData } from "@/interfaces/BlogCardData.interface";
import ApiService from "../services/ApiService";

@Component({
  components: { IconLoading, BlogPostCard }
})
export default class Tabs extends Vue {
  @Prop() private tabData!: iTab;

  private postData: Array<IBlogCardData> = [];

  private loadingStatus = "";

  private observer!: IntersectionObserver;

  async mounted() {
    // Get 18 posts for each tab and create an array from the returned promises
    const results = await this.getPosts(this.tabData.key, 18, 0);

    if (results) {
      this.observer = new IntersectionObserver(
        entries => {
          entries.forEach(async entry => {
            // Return if intersection is not "seen"
            if (!entry.isIntersecting) {
              return;
            }

            await this.getPosts(this.tabData.key, 9, this.postData.length);
          });
        },
        {
          root: null,
          threshold: 0,
          rootMargin: "0px"
        }
      );

      this.observePosts(this.tabData.key);
    }
  }

  destroyed() {
    if (this.observer !== undefined) {
      /* loop through this.tabs and set each posts array to empty */
      // Kill observer on component destroy
      this.observer.disconnect();
    }
  }

  private observePosts(key: string) {
    if (this.observer !== undefined) {
      const loadMorePosts = document.getElementById(`js-load-more-${key}`);
      if (loadMorePosts instanceof HTMLElement) {
        this.observer.observe(loadMorePosts);
      }
    }
  }

  private unobservePosts(key: string) {
    if (this.observer !== undefined) {
      const loadMorePosts = document.getElementById(`js-load-more-${key}`);
      if (loadMorePosts instanceof HTMLElement) {
        this.observer.unobserve(loadMorePosts);
      }
    }
  }

  private async getPosts(type: string, limit: number, start: number) {
    this.unobservePosts(this.tabData.key);

    if (this.loadingStatus !== "LOADING") {
      this.loadingStatus = "LOADING";

      let fileName;
      switch (type) {
        case "event":
          fileName = "eventPosts";
          break;
        case "casestudy":
          fileName = "caseStudyPosts";
          break;
        case "resource":
          fileName = "resourcePosts";
          break;
        case "news":
          fileName = "newsPosts";
          break;
        case "blog":
        default:
          fileName = "blogPosts";
          break;
      }

      this.postData.push({}, {}, {}, {}, {}, {});
      const data = await ApiService.get(
        `https://www.leighton.com/resources/blog/${fileName}.json`
      );
      this.postData.splice(-6);

      if (!data.length) {
        this.loadingStatus = "NOPOSTS";
      } else {
        this.loadingStatus = "COMPLETE";

        const section = data.slice(start, start + limit);

        if (section.length) {
          for (let i = 0; i < section.length; i++) {
            const blogPost = section[i];

            this.postData.push(blogPost);
          }

          this.observePosts(this.tabData.key);
        }
      }
    }

    return true;
  }
}
