
import { Component, Prop, Vue } from "vue-property-decorator";
import { Tabs as ITabs } from "@/interfaces/Tabs.interface";
import BlogTab from "@/components/BlogTab.vue";

@Component({
  components: { BlogTab }
})
export default class Tabs extends Vue {
  @Prop() private customClass!: string;
  @Prop() private tabs!: ITabs;

  private selected = 0;
  private selectOpen = false;

  private handleClickTab(type: number) {
    this.selected = type;
    this.$emit("changed", type);
  }
}
